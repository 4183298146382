<template>
  <div>
    <h1>{{ id ? '修改' : '新建' }}代理</h1>
    <el-form
      @submit.native.prevent="save"
      label-width="100px"
      class="el-form--max-width-400"
    >
      <el-row>
        <el-col>
          <el-form-item label="代理 URL">
            <el-input v-model="model.url" />
          </el-form-item>
          <el-form-item label="开启状态">
            <el-switch
              :active-value="1"
              :inactive-value="0"
              v-model="model.status"
            />
          </el-form-item>
          <el-form-item label="备注">
            <el-input v-model="model.remarks" type="textarea" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" native-type="submit">保存</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    id: String,
  },
  data() {
    return {
      model: {
        url: '',
        status: 0,
        remarks: '',
      },
    }
  },
  created() {
    this.id && this.fetch()
  },
  methods: {
    async fetch() {
      const { data: proxies } = await this.$http.get(`proxies/${this.id}`)
      this.model = Object.assign(this.model, proxies)
    },
    async save() {
      if (this.id) {
        await this.$http.put(`proxies/${this.id}`, this.model)
      } else {
        await this.$http.post('proxies', this.model)
      }
      this.$router.push('/proxy/list')
      this.$message.success('保存成功')
    },
  },
}
</script>
